import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faTwitter,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "gatsby";
import NewsletterBannerForm from "../newsletter-banner";
const Footer = ({ lang }) => {
  let readPrivacy, privacyPolicy;
  if (lang.toLowerCase() === "es") {
    readPrivacy = "Nos preocupamos por tus datos, lee nuestra";
    privacyPolicy = "Política de privacidad.";
  } else {
    readPrivacy = "We care about the protection of your data. Read our";
    privacyPolicy = "Privacy Policy.";
  }

  const sociallinks = [
    {
      name: "Twitter",
      url: "https://twitter.com/victorsolaya",
      icon: faTwitter
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/victorsanchezolaya",
      icon: faLinkedinIn
    },
    {
      name: "Github",
      url: "https://github.com/victorsolaya",
      icon: faGithub
    }
  ];
  return (
    <>
      <NewsletterBannerForm lang={lang} />
      <div className="social-links">
        {sociallinks.map(social => {
          return (
            <a href={social.url} className="social-icon" key={social.name}>
              <FontAwesomeIcon icon={social.icon} />
            </a>
          );
        })}
        <p>© Victor Sanchez {new Date().getFullYear()}</p>
        <p className="mt-3 text-sm text-indigo-200">
          {readPrivacy}
          <Link to="/privacy" className="text-white font-medium underline pl-1">
            {privacyPolicy}
          </Link>
        </p>
      </div>
    </>
  );
};

export default Footer;
