import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "gatsby";

const Dropdown = ({ lang, location, classContainer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const languages = [
    { key: 1, name: "Español 🇪🇸", href: "es", lang: "es" },
    { key: 2, name: "English 🇬🇧", href: "en", lang: "en" }
  ];
  /**
   * Convert the href to other language
   * @param {string} href
   */
  function LanguageSelector(lang) {
    if (lang === "default" || lang === "en") {
      if (location.pathname === "/es") {
        return location.pathname.replace("/es", "/");
      }
      return location.pathname.replace("/es", "");
    } else {
      if (!location.pathname.startsWith("/es")) {
        return `/es${location.pathname === "/" ? "" : location.pathname}`;
      }
    }
    return location.pathname;
  }
  return (
    <div className={classContainer || "relative inline-block text-left"}>
      <div>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="options-menu"
          aria-haspopup="true"
        >
          {lang === "es" ? "Idioma 🇪🇸" : "Language 🇬🇧"}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <Transition
        show={isOpen}
        enter="transition-ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition-ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {languages.map(lang => {
              return (
                <Link
                  className="block px-4 py-2 m-0 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  key={lang.key}
                  to={LanguageSelector(lang.lang)}
                >
                  {lang.name}
                </Link>
              );
            })}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Dropdown;
