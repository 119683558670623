import React from "react";
import { Location } from "@reach/router";

import HeaderComponent from "../components/layout/header";
import Footer from "../components/layout/footer";
import "../assets/layout.css";
import "../tailwind.output.css";
const Layout = props => {
  const { children, lang } = props;
  return (
    <>
      <Location>
        {({ location }) => (
          <>
            <header>
              <HeaderComponent lang={lang} location={location} />
            </header>
            <main className="mainsection">{children}</main>
            <footer className="footer">
              <Footer lang={lang} />
            </footer>
          </>
        )}
      </Location>
    </>
  );
};

export default Layout;
