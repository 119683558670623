import React, { useState } from "react";
import { Link } from "gatsby";
import Dropdown from "../dropdown";
import Logo from "../logo";
// import NewsletterBannerForm from "../newsletter-banner";
const HeaderComponent = ({ lang, location }) => {
  console.log(lang);
  const [isOpen, setIsOpen] = useState(true);
  let links = [];
  if (lang === "en") {
    links = [{ key: 1, url: `/blog`, name: "Blog" }];
  } else {
    links = [
      { key: 1, url: `/${lang}/blog`, name: "Blog" },
      { key: 2, url: `/${lang}/cursos`, name: "Cursos" }
    ];
  }
  return (
    <>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="flex items-center px-2 lg:px-0">
              <div className="flex-shrink-0">
                <Link to={`/${lang === "en" ? "" : lang}`}>
                  <Logo title="Victor Sanchez" />
                </Link>
              </div>
              <div className="hidden lg:block lg:ml-6">
                <div className="flex space-x-4">
                  {links.map(link => {
                    return (
                      <Link
                        key={link.key}
                        to={link.url}
                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-xl font-large uppercase"
                      >
                        {link.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <Dropdown
              classContainer="relative inline-block hidden lg:block"
              lang={lang}
              location={location}
            />
            <div className="flex lg:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`block h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div
            className={`${
              isOpen ? "hidden" : "block"
            } lg:hidden flex justify-between`}
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              {links.map(link => {
                return (
                  <Link
                    key={link.key}
                    to={link.url}
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    {link.name}
                  </Link>
                );
              })}
            </div>
            <Dropdown
              classContainer="px-2 pt-2 pb-3 space-y-1"
              lang={lang}
              location={location}
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderComponent;
