import React, { useState } from "react";
import ErrorAlert from "./alerts/errorAlert";
import SuccessAlert from "./alerts/successAlert";
const NewsletterBannerForm = ({ lang }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const [status, setStatus] = useState(null);
  let title,
    errorMessage,
    successMessage,
    firstnamePlaceholder,
    emailPlaceholder,
    notifyMe,
    firstNameLabel,
    emailAddressLabel;

  if (lang.toLowerCase() === "es") {
    title = "Únete a la newsletter";
    errorMessage =
      "Oh noo...Ha habido un error! Contactáctame si persiste el problema a me@victorsolaya.com";
    successMessage = "Wohoo! Confirma tu suscripción";
    firstnamePlaceholder = "Pon tu nombre";
    emailPlaceholder = "Pon tu email";
    notifyMe = "Notifícame";
    firstNameLabel = "Nombre";
    emailAddressLabel = "Email";
  } else {
    title = "Join the newsletter";
    errorMessage =
      "Oh noo...There was an error! Contact me if the problem persists at me@victorsolaya.com";
    successMessage = "Woohoo! Confirm your subscription!";
    firstnamePlaceholder = "Enter your first name";
    emailPlaceholder = "Enter your email";
    notifyMe = "Notify me";
    firstNameLabel = "First name";
    emailAddressLabel = "Email address";
  }

  // your generated URL here //
  const baseURL =
    lang.toLowerCase() === "es"
      ? process.env.GATSBY_MAIN_FORM_URL_ES
      : process.env.GATSBY_MAIN_FORM_URL;
  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const res = await fetch(baseURL, {
        method: "POST",
        body: formData,
        headers: {
          accept: "application/json"
        }
      });
      setEmail("");
      setFirstName("");
      const json = await res.json();
      if (json.status === "success") {
        setStatus("SUCCESS");
        return;
      }
    } catch (err) {
      setStatus("ERROR");
    }
  };

  const handleInputChange = e => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleFirstNameOnChange = e => {
    const { value } = e.target;
    setFirstName(value);
  };

  return (
    <div className="mx-auto mb-12">
      <div className="px-2 py-2 bg-indigo-700 rounded-lg xl:flex xl:items-center">
        <div className="mt-2 sm:w-full xl:mt-0 xl:ml-8 flex">
          <div className="flex-col flex-wrap w-1/3 self-center">
            <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl py-2">
              {title}
            </h2>
          </div>

          <div className="sm:flex space-y-2 space-x-2 flex-col w-2/3">
            <form
              className="sm:flex space-y-2 space-x-2 flex-row p-2 mb-0"
              action={baseURL}
              method="post"
              onSubmit={handleSubmit}
            >
              <label htmlFor="firstName" className="sr-only">
                {firstNameLabel}
              </label>
              <input
                id="firstName"
                name="fields[first_name]"
                type="text"
                required
                className="w-full border-white px-5 py-3 text-indigo-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md"
                onChange={handleFirstNameOnChange}
                value={firstName}
                placeholder={firstnamePlaceholder}
              />

              <label htmlFor="emailAddress" className="sr-only">
                {emailAddressLabel}
              </label>
              <input
                id="emailAddress"
                name="email_address"
                type="email"
                required
                className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md text-indigo-900"
                onChange={handleInputChange}
                value={email}
                placeholder={emailPlaceholder}
              />
              <button
                type="submit"
                className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-blue-500 hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white sm:mt-0 sm:w-auto sm:flex-shrink-0"
              >
                {notifyMe}
              </button>
            </form>
            {status === "SUCCESS" && (
              <SuccessAlert
                text={successMessage}
                closeAlert={() => setStatus("")}
              />
            )}
            {status === "ERROR" && (
              <ErrorAlert
                text={errorMessage}
                closeAlert={() => setStatus("")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterBannerForm;
