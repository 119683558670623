import React from "react";

const Logo = props => {
  const { title } = props;
  return (
    <>
      <svg
        className="inline-block"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40.000000 46.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.15, written by Peter Selinger 2001-2017
        </metadata>
        <g
          transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
          fill="#fff"
          stroke="none"
        >
          <path
            d="M111 453 c0 -4 31 -98 68 -208 92 -271 87 -271 179 4 59 177 68 211
53 211 -13 0 -29 -37 -70 -162 -30 -90 -58 -174 -64 -188 -8 -22 -17 -2 -72
160 -44 132 -66 186 -78 188 -9 2 -17 0 -16 -5z"
          />
        </g>
      </svg>
      <span className="logo">{title}</span>
    </>
  );
};
export default Logo;
